<template>
  <div>
    <v-alert
      :prominent="!$vuetify.breakpoint.xs"
      class="ma-0 rounded-t-0"
      type="error"
      text
    >
      Bestätige die Wahl deiner myGymer-Angebote bis am
      <DateValue :value="choice.endDate" long /> um {{ choice.endTime }}.
    </v-alert>
    <v-card class="rounded-t-0" flat :loading="loading">
      <v-divider />
      <v-card-actions>
        <v-btn text block small :to="{ name: 'StudentOptional' }"
          >zur Wahl</v-btn
        ></v-card-actions
      >
    </v-card>
  </div>
</template>
<script>
import DateValue from "common/components/DateValue.vue";

export default {
  components: { DateValue },
  data() {
    return {
      loading: false,
      choice: {},
    };
  },
  computed: {},
  methods: {
    async fetchData() {
      this.loading = true;
      this.choice = await this.apiList({ resource: "optional/choice" });

      if (this.choice.enabled && !this.choice.hasConfirmed) {
        this.$emit("loaded");
      }
      this.loading = false;
    },
  },
  async created() {
    this.fetchData();
  },
};
</script>
